<template>
  <div>
    <passContainer
      :sections="sections"
      :activeSection="activeSection"
    ></passContainer>
    <titleForm
      :title="
        'Creando un archivo en la sede ' + branchName + ' y en la carpeta '
      "
      :strong="folderParent"
    ></titleForm>
    <form
      id="fileForm"
      class="mainForm mb-3 needs-validation"
      v-bind:class="{ 'was-validated': showValidate }"
    >
      <div class="xScroll d-flex">
        <first
          :submitValidate="submitStart"
          :sectionName="sections[0]"
          sectionPosition="0"
          submitName="Crear Archivo"
          @moveForm="moveForm($event)"
          @submitForm="submit()"
        ></first>
      </div>
    </form>
    <Legal></Legal>
  </div>
</template>

<style lang="css" scoped>
.xScroll {
  margin-left: 0%;
  transition: 0.3s ease-in-out;
}
.mainForm {
  z-index: 8;
  position: absolute;
  width: 100vw;
  overflow-x: hidden;
}
</style>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import Legal from "@/components/layout/Legal";
import passContainer from "@/components/form/passContainer";
import titleForm from "@/components/form/Title";

import { validateFormData } from "@/modules/tools";

import first from "@/views/document/form/1";

export default {
  name: "Document Main Branch File Create",
  data() {
    return {
      sections: ["Datos Básicos"],
      activeSection: "",
      submitStart: false,
      showValidate: false,
      branchName: "",
      folderParent: ""
    };
  },
  components: {
    Legal,
    passContainer,
    titleForm,
    first
  },
  async created() {
    this.activeSection = this.sections[0];
    const store = new Storage();
    const planData = await store.getData(
      "plan",
      localStorage.getItem("subscription.plan")
    );
    const fileData = await store.getData("file");
    const limitSize = planData.restrictions.storage;
    let totalSize = 0;
    fileData.forEach(file => {
      totalSize += file.size;
    });
    if (totalSize >= limitSize) {
      this.$router.go(-1);
    }

    store.getData("branch", this.$route.params._id).then(branch => {
      store.getData("city", branch.city).then(city => {
        this.branchName = city.name;
      });
    });
    store.getData("file", this.$route.params.fileId).then(file => {
      this.folderParent = file.name;
    });
  },
  methods: {
    submit() {
      const fileForm = document.getElementById("fileForm");
      const select = fileForm.getElementsByTagName("select");
      const input = fileForm.getElementsByTagName("input");
      let submit = true;

      this.submitStart = !this.submitStart;
      this.showValidate = true;

      for (let i = 0; i < select.length; i++) {
        if (!validateFormData(select[i])) {
          submit = false;
        }
      }
      for (let i = 0; i < input.length; i++) {
        if (!validateFormData(input[i])) {
          submit = false;
        }
      }

      if (submit) {
        this.sendData(fileForm);
      }
    },
    sendData(form) {
      const store = new Storage();
      const formData = new FormData(form);

      Swal.fire({
        icon: "warning",
        title: "Creando el documento",
        html:
          "Por favor espere mientras se crea el documento</b> " +
          '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });

      const data = {
        createdBy: localStorage.getItem("user._id"),
        branch: this.$route.params._id,
        company: this.companyId,
        expiration: new Date(formData.get("endDate")).getTime(),
        extension: formData.get("extension"),
        name: formData.get("name"),
        parent: this.$route.params.fileId,
        responsable: formData.get("responsable"),
        size: parseInt(formData.get("fileSize"))
      };

      if (!formData.get("priority")) {
        data.expiration = null;
        data.responsable = null;
      }

      store
        .insertData("optima", "file", [data])
        .then(data => {
          const progressBranch = document.getElementById("progressBranch");
          const percentUpdate = (loaded, total, percent) => {
            percent -= 5;
            progressBranch.innerText = percent.toString() + "%";
            progressBranch.style.width = percent.toString() + "%";
          };

          formData.append("_id", data[0]._id);
          formData.append("parent", this.$route.params.fileId);
          store
            .uploadFile(
              "file",
              data[0]._id,
              "optima/file/upload",
              formData,
              percentUpdate
            )
            .then(res => {
              this.endSubmit(true);
            })
            .catch(error => {
              console.log(error);
              this.endSubmit(false);
            });
        })
        .catch(error => {
          console.log(error);
          this.endSubmit(false);
        });
    },
    endSubmit(status) {
      if (status) {
        Swal.fire({
          icon: "success",
          title: "Documento creado",
          text: "Todos los datos han sido sincronizados"
        }).then(result => {
          this.$router.go(-1);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al crear el documento",
          text:
            "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
        }).then(result => {
          this.$router.go(-1);
        });
      }
    }
  }
};
</script>
